<template>
	<div class="grid lg:grid-cols-3 gap-5">
		<div class="lg:col-span-2 h-full overflow-y-auto">
			<n-data-table title="供應商列表" :columns="fields" :data="suppliers" actions="view,edit,search,refresh" @view="getSupplier" @edit="archiveSupplier">
				<template v-slot:name="{ row }"> {{ row.name }}</template>
				<template v-slot:merchantId="{ row }"> {{ merchantName(row.merchantId).name['tc'] }}</template>
				<template v-slot:status="{ row }">
					<n-badge :color="statusName(row.status).color"> {{ statusName(row.status).label }}</n-badge>
				</template>
			</n-data-table>
		</div>
		<div class="lg:col-span-1 space-y-2 text-xl h-full overflow-auto">
			<p class="text-4xl pb-2">{{ supplier.id ? '修改' : '新增' }}供應商</p>
			<div class="flex space-x-2">
				<label for="merchant">所屬商戶</label>
				<n-select v-model="supplier.merchantId" :options="merchants" :reduce="merchant => merchant.id" :get-option-label="merchant => merchant.name['tc']" class="w-full h-full text-base" placeholder="請選擇商戶"></n-select>
			</div>
			<template v-if="supplier.merchantId">
				<div class="flex space-x-2">
					<label for="name">供應商名稱</label>
					<n-input id="name" type="text" placeholder="請輸入名稱" v-model="supplier.name" />
				</div>

				<div class="flex space-x-2">
					<label for="status">供應商狀態</label>
					<n-select v-model="supplier.status" :options="statusOptions" :reduce="status => status.value" class="w-full h-full text-base" placeholder="請選擇狀態"></n-select>
				</div>
			</template>
			<p v-if="error" class="text-danger">{{ '錯誤訊息:' + error }}</p>

			<div class="flex space-x-2">
				<n-button v-if="supplier.id" @onClick="$router.go(0)" color="danger">返回</n-button>
				<n-button @onClick="supplier.id ? updateSupplier(supplier) : createSupplier(supplier)" color="primary">{{ supplier.id ? '修改供應商' : '加入供應商' }} </n-button>
			</div>
		</div>
	</div>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { supplier } from '@/model/supplier'
export default {
	data() {
		return {
			supplier: supplier(),
			fields: [
				{
					label: '供應商名稱',
					prop: 'name',
					sortable: true,
				},
				{
					label: '所屬商戶',
					prop: 'merchantId',
					sortable: true,
				},
				{
					label: '供應商狀態',
					prop: 'status',
					sortable: true,
				},
			],
			error: '',
		}
	},
	computed: {
		...mapState(['statusOptions']),
		...mapGetters([
			// merchant
			'merchants',
			'merchantName',
			// supplier
			'suppliers',
			'currentSupplier',
			// catalog
			'catalogItem',
			'catalogByMerchantId',
			// state
			'statusName',
		]),
	},
	methods: {
		async createSupplier(data) {
			try {
				await this.$store.dispatch('createSupplier', { supplier: data })
				this.$router.go(0)
			} catch (error) {
				console.log(error)
				this.error = error.message
			}
		},
		async getSupplier(row) {
			try {
				const id = row.id
				this.error = ''
				await this.$store.dispatch('getSupplier', { id })
				this.supplier = this.currentSupplier
			} catch (error) {
				console.log(error)
				this.error = error.message
			}
		},
		async archiveSupplier(row) {
			try {
				const id = row.id
				this.error = ''
				await this.$store.dispatch('archiveSupplier', { id })
				this.$router.go(0)
			} catch (error) {
				console.log(error)
				this.error = error.message
			}
		},
		async updateSupplier(data) {
			try {
				this.error = ''
				await this.$store.dispatch('updateSupplier', { id: data.id, supplier: data })
				this.$router.go(0)
			} catch (error) {
				console.log(error)
				this.error = error.message
			}
		},
	},
}
</script>
